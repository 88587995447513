import api from "@api";
import route from "@/router";
import { getSearchResultOnRow } from "@/EK-core/util";
export default {
    state: {
        sellPointsList: [],
        sellPointDto: {
            name: "",
            phoneNumber: "",
            dateCreated: "",
            email: "",
            password: "",
            discountLimit: 0,
            moneyLimit: 0,
            rate: 0,
            address: "",
            canDiscount: false,
            isHidden: false,
        },
        sellPointStatus: [
            { text: "غير فعال", value: true, color: "danger" },
            { text: "فعال", value: false, color: "success" },
        ],
        sellPointStatusHidden: [
            { text: "مخفي", value: true, color: "danger" },
            { text: "ظاهر", value: false, color: "success" },
        ],
        dtoUpdateSellPoint: {
            isBlocked: false,
            isHidden: false,
            id: "",
            name: "",
            email: "",
            phoneNumber: "",
            newPassword: "",
            address: "",
            discountLimit: 0,
            moneyLimit: 0,
            rate: 0,
            codes: [],
            canDiscount: false,
        },
        columns: [
            {
                label: " الاسم المندوب ",
                field: "name",
            },
            {
                label: " العنوان ",
                field: "address",
            },
            {
                label: " رقم الهاتف ",
                field: "phoneNumber",
            },
            {
                label: " تاريخ الاشتراك ",
                field: "dateCreated",
            },
            {
                label: "عدد الرموز المباعة",
                field: "codesCount",
            },
            {
                label: "حالة الاشتراك",
                field: "isBlocked",
            },
            {
                label: "حالة نقطة البيع",
                field: "isHidden",
            },
            {
                label: " التفاصيل",
                field: "details",
            },
        ],
        columnsCode: [
            {
                label: "اسم الكود",
                field: "code",
            },
            {
                label: "أسماء المواد",
                field: "subjetsNames",
            },
            {
                label: "السعر",
                field: "price",
            },
            {
                label: "تاريخ الاشتراك",
                field: "startDate",
            },
            {
                label: "انتهاء الاشتراك",
                field: "endDate",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
    },
    mutations: {
        SET_SELL_POINT(state, payload) {
            console.log(payload);
            state.sellPointsList = payload;
            console.log(state.sellPointsList);
        },
        RESET_SELL_POINTS_DTO(state) {
            state.sellPointDto = {
                name: "",
                phoneNumber: "",
                dateCreated: "",
                email: "",
                password: "",
                discountLimit: 0,
                moneyLimit: 0,
                address: "",
                canDiscount: false,
                isHidden: false,
            };
        },
        ADD_SELL_POINT(state, payload) {
            state.sellPointsList.unshift(payload);
        },
        GET_BYID_SELL_POINT(state, paylaod) {
            Object.assign(state.dtoUpdateSellPoint, paylaod);
        },
        DELETE_SELL_POINT(state, payload) {
            state.sellPointsList.filter((ele) => ele.id != payload);
            route.push("/admin/sellpoints");
        },
        DELETE_SELL_POINTS(state, payload) {
            state.sellPointsList = state.sellPointsList.filter(
                (el) => !payload.includes(el.id)
            );
        },
        UPDATE_SELL_POINT(state, paylaod) {
            state.dtoUpdateSellPoint = { ...paylaod };
        },
        ISBLOCK_SELL_POINT(state, payload) {
            state.dtoUpdateSellPoint.isBlocked = payload;
        },
    },
    actions: {
        getAllSellPoints({ commit }) {
            api.get("SellPoint/GetAll", ({ data }) => {
                commit("SET_SELL_POINT", data);
                console.log(data);
            });
        },
        getByIdSellPoint({ commit }, id) {
            api.get(`SellPoint/Dash/GetById?id=${id}`, ({ data }) => {
                commit("GET_BYID_SELL_POINT", data);
            });
        },
        addSellPoint({ commit }, paylaod) {
            api.post(
                "SellPoint/Dash/Add",
                paylaod,
                ({ data }) => {
                    commit("ADD_SELL_POINT", data);
                },
                {
                    success: "تم اضافة هذه النقطة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },

        deleteSellPoint({ commit }, id) {
            api.delete(
                `SellPoint/Dash/Delete?id=${id}`,
                ({ data }) => {
                    commit("DELETE_SELL_POINT", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف هذه النقطة   ",
                    success: "تم حذف هذه النقطة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteRangeSellPoints({ commit }, ids) {
            api.delete(
                "SellPoint/Dash/DeleteRange",
                () => {
                    commit("DELETE_SELL_POINTS", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف نقطة البيع المحددة",
                    success: "تم حذف هذه النقطة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        updateSellPoint({ commit }, paylaod) {
            api.post(
                "SellPoint/Dash/Modify",
                paylaod,
                ({ data }) => {
                    commit("UPDATE_SELL_POINT", data);
                    route.push("/admin/sellpoints");
                },
                {
                    success: "تم تعديل هذه النقطة بنجاح",
                    error: "فشل التعديل ..يرجى اعادة المحاولة ",
                }
            );
        },
        blockSellPoint({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_SELL_POINT", true);
                },
                {
                    success: "تم حظر هذه النقطة بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
        unblockSellPoint({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_SELL_POINT", false);
                },
                {
                    success: "تم الغاء حظر النقطة بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
    },
    getters: {
        searchListSellPoints({ sellPointsList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return sellPointsList.filter((ele) => {
                return (
                    getSearchResultOnRow(filter, ele) ||
                    ele.address.indexOf(query) != -1 ||
                    ele.name.indexOf(query) != -1 ||
                    ele.phoneNumber == query
                );
            });
        },
    },
};
