import api from "@/EK-core/api";
import { getSearchResultOnRow } from "@/EK-core/util";
// import { getUserData  , getUserRoles} from "@/app/admin/auth/utils";
// import { SuperAdmin } from "@/router";

export default {
    state: {
        unitsOptions: [],
        unitsList: [],
        filterValueType: "",
        isDialogOpen: false,
        unitDto: {
            id: "",
            name: "",
            semester: 1,
            subjectId: "",
            // teacherId: "",
            semesterId: "",
            isFree: true,
            price: 0,
            order: "",
        },
        unitDetailsDto: {
            id: "",
            name: "",
            subjectName: "",
            subjectId: "",
            semesterId: "",

            semester: "",
            teacherName: "",
            price: "",
            dateCreated: "",
            lessonsCount: 0,
            isFree: true,
            order: "",
            lessons: [],
            chapters: [],

            subCount: 0,
        },

        unitLessonDto: {
            id: "",
            discroption: "",
            isFree: false,
            url: "",
            qestions: [],
        },

        semesterList: [
            { id: 1, name: "الفصل الاول" },
            { id: 2, name: "الفصل الثاني" },
            { id: 3, name: "الجلسات الامتحانية" },
        ],
        lessonTypeList: [
            { id: 0, name: " الكل" },
            { id: 1, name: "مقطع فيديو" },
            { id: 2, name: "ملف" },
            { id: 3, name: " اختبار" },
            { id: 4, name: "بث مباشر" },
        ],
        lessonUnitcols: [
            {
                field: "name",
                label: "اسم البحث",
            },
            {
                field: "order",
                label: " ترتيب",
            },
            // {
            //     field: "dateCreated",
            //     label: "تاريخ الاضافة",
            // },
            // {
            //     field: "lessonType",
            //     label: "نوع المستند",
            // },
            // {
            //     field: "questionsCount",
            //     label: "عدد الاسئلة",
            // },
            // {
            //     field: "details",
            //     label: "تفاصيل",
            // },
        ],
    },

    mutations: {
        SET_UNITS_OPTIONS(state, payload) {
            state.unitsOptions = [...payload];
        },
        GET_ALL_UNITS(state, payload) {
            state.unitsList = payload;
        },
        ADD_UNITS(state, payload) {
            state.unitsList.unshift(payload);
        },
        ADD_UNIT_LESSON(state, payload) {
            console.log("Mutated", payload);
            state.unitDetailsDto.lessons.unshift({
                ...payload,
                questionsCount: 0,
            });
        },
        SET_UNIT_DTO(state, payload) {
            if (payload) {
                state.unitDto = { ...payload };
            } else {
                state.unitDto = {
                    name: "",
                    subjectId: "",
                    semester: 1,
                    teacherId: "",
                    price: 0,
                    isFree: true,
                    order: 0,
                };
            }
        },
        RESET_DETAILS_UNIT(state) {
            state.unitDetailsDto = {
                id: "",
                name: "",
                subjectName: "",
                semesterId: "",
                semester: 1,
                teacherName: "",
                price: "",
                dateCreated: "",
                lessonsCount: 0,
                isFree: true,
                order: 0,
                lessons: [],
            };
        },

        UPDATE_DTO_URL(state, payload) {
            state.unitLessonDto = {
                ...state.unitLessonDto,
                url: payload,
                isDecrypted: true,
            };
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        // GET_BYID_UNIT(state, payload) {
        //     // let semester = state.semesterList.find(
        //     //     ele => ele.id == payload.semester
        //     // );
        //     state.unitDetailsDto = {
        //         ...payload

        //     };
        // },
        GET_BYID_UNIT(state, paylaod) {
            Object.assign(state.unitDetailsDto, paylaod);
        },
        SET_UNIT_LESSON_DTO(state, payload) {
            if (payload) {
                state.unitLessonDto = { ...payload };
            } else {
                state.unitLessonDto = {
                    id: "",
                    discroption: "",
                    url: "",
                    qestions: [],
                };
            }
        },
        // UPDATE_UNIT(state, payload) {
        //     state.unitsList = state.unitsList.map((el) => {
        //         if (el.id == payload.id) {
        //             return payload;
        //         } else {
        //             return el;
        //         }
        //     });
        // },
        UPDATE_UNIT(state, paylaod) {
            state.unitDetailsDto = { ...paylaod };
            console.log(paylaod, "paylaod");
        },
        DELETE_UNIT(state, payload) {
            state.unitsList = state.unitsList.filter(
                (ele) => ele.id != payload
            );
        },
        FILTER_FOR_TYPE(state, payload) {
            state.filterValueType = payload;
        },
        Delete_Lessons(state, ids) {
            state.unitDetailsDto.lessons = state.unitDetailsDto.lessons.filter(
                (el) => !ids.includes(el.id)
            );
        },
    },

    actions: {
        getIdLessonUnit({ commit }, payload) {
            commit("FILTER_FOR_TYPE", payload);
        },
        getUnitsOptions({ commit }) {
            api.get("Syllabus/Dash/GetUnitsId", ({ data }) => {
                commit("SET_UNITS_OPTIONS", data);
            });
        },

        getAllUnits({ commit }) {
            api.get("Syllabus/Dash/GetAllUnit", ({ data }) => {
                commit("GET_ALL_UNITS", data);
            });
        },
        getByIdUnit({ commit }, id) {
            api.get(`Syllabus/Dash/GetByIdUnit?id=${id}`, ({ data }) => {
                commit("GET_BYID_UNIT", data);
                // console.log(data);
            });
        },

        getByIdVideo({ commit }, id) {
            return new Promise((resolve) => {
                api.get(`Syllabus/Dash/GetByIdVideo?id=${id}`, ({ data }) => {
                    resolve(data);
                    commit("SET_UNIT_LESSON_DTO", { ...data, lessonType: 1 });
                    // console.log(data);
                });
            });
        },
        getByIdFile({ commit }, id) {
            return new Promise((resolve) => {
                api.get(`Syllabus/Dash/GetByIdFile?id=${id}`, ({ data }) => {
                    resolve(data);
                    commit("SET_UNIT_LESSON_DTO", { ...data, lessonType: 2 });
                    // console.log(data);
                });
            });
        },
        getByIdQuiz({ commit }, id) {
            return new Promise((resolve) => {
                api.get(`Syllabus/Dash/GetByIdQuiz?id=${id}`, ({ data }) => {
                    console.log("unit", data);
                    resolve(data);
                    commit("SET_UNIT_LESSON_DTO", {
                        questions: [...data],
                        id,
                        lessonType: 3,
                    });

                    // console.log(data);
                });
            });
        },
        addUnits({ commit, dispatch }, payload) {
            api.post(
                "Syllabus/Dash/AddUnit",
                payload,
                ({ data }) => {
                    commit("ADD_UNITS", data);
                    dispatch("getUnitsOptions");
                },
                {
                    success: "تم اضافة الوحدة بنجاح",
                    error: "فشل اضافة الوحدة ..يرجى اعادة المحاولة ",
                }
            );
        },
        updateUnit({ commit }, payload) {
            api.post(
                "Syllabus/Dash/ModifyUnit",
                payload,
                ({ data }) => {
                    commit("UPDATE_UNIT", data);
                    // commit("SET_UNIT_DTO");
                },
                {
                    success: "تم تعديل الوحدة بنجاح",
                    error: "فشل تعديل الوحدة ..يرجى اعادة المحاولة ",
                }
            );
        },

        deleteUnit({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteUnit?id=${id}`,

                () => {
                    commit("DELETE_UNIT", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الوحدة",
                    success: "تم حذف الوحدة بنجاح",
                    error: "فشل خذف الوحدة ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteRangeLessons({ commit }, ids) {
            api.delete(
                "Syllabus/Dash/DeleteRangeLesson",
                () => {
                    commit("Delete_Lessons", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الدروس المحددة",
                    success: "تم حذف الدروس بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
    getters: {
        // unitsListResult({ unitsList }, getters, { filter }) {
        //      let query = filter.searchDto.query;
        //     return unitsList.filter(el => {
        //         return (
        //            ( getSearchResultOnRow(filter, el) ||
        //             el.name.indexOf(query) != -1 ||
        //             el.semester == query ||
        //             el.studentsCount == query ||
        //             el.lessonsCount == query) && (el.teacherId == getUserData().id || getUserRoles() == SuperAdmin)
        //         );
        //     });
        // },
        unitsListResult({ unitsList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return unitsList.filter((el) => {
                return (
                    getSearchResultOnRow(filter, el) ||
                    el.name.indexOf(query) != -1 ||
                    el.semester == query ||
                    el.studentsCount == query ||
                    el.lessonsCount == query
                );
            });
        },
        resaultUnitLessons(
            { unitDetailsDto, lessonTypeList, filterValueType },
            getters,
            { filter }
        ) {
            let query = filter.searchDto.query;
            return unitDetailsDto.lessons.filter((el) => {
                return (
                    (el.name.indexOf(query) != -1 ||
                        lessonTypeList
                            .find((item) => item.id == el.lessonType)
                            .name.indexOf(query) != -1 ||
                        el.lessonType == query ||
                        el.questionsCount == query ||
                        el.order == query) &&
                    (el.lessonType == filterValueType || !filterValueType)
                );
            });
        },
    },
};
