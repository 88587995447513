import { Admin, Guide, SuperAdmin, Teacher, DataEntry } from "@/router";

export default [
    {
        path: "/",
        redirect: "/admin/home",
    },
    {
        path: "/admin/home",
        name: "home",
        component: () => import("@admin/home/Home.vue"),
        meta: () => ({
            pageTitle: "الرئيسية",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الرئيسية",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/classes",
        name: "classes",
        components: {
            default: () => import("@admin/classes/Classes.vue"),
            actions: () => import("@admin/classes/components/AddClass.vue"),
        },
        meta: () => ({
            pageTitle: "الصفوف",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الصفوف",
                },
            ],
        }),
    },
    {
        path: "/admin/classes/:id",
        name: "classDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/classes/pages/ClassDetail.vue"),
            actions: () =>
                import("@admin/classes/components/ClassSubjectSearch.vue"),
        },
        meta: (route) => ({
            pageTitle: "الصفوف",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الصفوف",
                    to: "/admin/classes",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/subjects",
        name: "subjects",
        components: {
            default: () => import("@admin/subjects/Subjects.vue"),
            actions: () =>
                import(
                    "@/app/admin/services/subjects/components/AddSubject.vue"
                ),
        },
        meta: () => ({
            pageTitle: "المواد",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                },
            ],
        }),
    },
    {
        path: "/admin/subjects/:id",
        name: "subjectDetails",
        props: true,
        component: () => import("@admin/subjects/pages/SubjectDetails.vue"),
        meta: () => ({
            pageTitle: "المواد",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                    to: "/admin/subjects",
                },
                {
                    text: " تفاصيل المادة ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/units",
        name: "units",
        components: {
            default: () => import("@admin/units/Units.vue"),
            actions: () => import("@admin/units/components/AddUnits.vue"),
        },
        meta: () => ({
            pageTitle: "الوحدات",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الوحدات",
                },
            ],
        }),
    },

    {
        path: "/admin/units/:id",
        name: "unitDetails",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () => import("@admin/units/pages/UnitDetails.vue"),
            actions: () => import("@admin/chapter/components/AddChapter.vue"),
        },
        meta: (route) => ({
            pageTitle: "الوحدات",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الوحدات",
                    to: "/admin/units",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/chapter",
        name: "chapter",
        components: {
            default: () => import("@admin/chapter/chapter.vue"),
            actions: () => import("@admin/chapter/components/AddChapter.vue"),
        },
        meta: () => ({
            pageTitle: "الأبحاث",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الأبحاث",
                },
            ],
        }),
    },
    {
        path: "/admin/chapter/:id",
        name: "ChapterDetails",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () => import("@admin/chapter/pages/ChapterDetails.vue"),
            actions: () => import("@admin/lessons/components/AddLesson.vue"),

        },
        meta: (route) => ({
            pageTitle: "الابحاث",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الابحاث",
                    to: "/admin/chapter",
                },
                {
                    text: route.query ? `تفاصيل` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/lessons",
        name: "lessons",
        components: {
            default: () =>
                import("@/app/admin/services/lessons/pages/Lessons.vue"),
            actions: () => import("@admin/lessons/components/AddLesson.vue"),
        },
        meta: () => ({
            pageTitle: "الدروس",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                },
            ],
        }),
    },
    {
        path: "/admin/lessons/:id",
        props: true,
        name: "lessonDetails",
        component: () =>
            import("@/app/admin/services/lessons/pages/LessonDetails.vue"),
        meta: () => ({
            pageTitle: "تفاصيل درس",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                    to: "/admin/lessons",
                },
                {
                    text: "تفاصيل الدرس",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/lessons/questions/:id",
        name: "detailsQuestion",
        props: true,
        component: () =>
            import(
                "@admin/questionAnswerDashBoard/incommingQuestion/pages/incommingQuestionDetails.vue"
            ),
        meta: () => ({
            pageTitle: "الدروس",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                    to: "/admin/lessons",
                },
                {
                    text: "تفاصيل الدرس",
                    active: false,
                },
                {
                    text: "تفاصيل السؤال",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/questionsBank",
        name: "questionsBank",
        components: {
            default: () =>
                import("@/app/admin/services/questionsBank/QuestionsBank.vue"),
            actions: () =>
                import("@admin/questionsBank/components/AddQuestion.vue"),
        },
        meta: () => ({
            pageTitle: "بنك الاسئلة",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "بنك الاسئلة",
                },
            ],
        }),
    },
    {
        path: "/admin/questionsBank/:quizId/:questionId",
        props: true,
        name: "questionDetails",
        component: () => import("@admin/questionsBank/pages/AddQuestion.vue"),
        meta: () => ({
            pageTitle: "بنك الاسئلة",
            roles: [Admin, SuperAdmin, Teacher, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "بنك الاسئلة",
                    to: "/admin/questionsBank",
                },
                {
                    text: "إضافة سؤال ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/questionAnswerDashBoard/incommingQuestion",
        name: "incommingQuestion",
        components: {
            default: () =>
                import(
                    "@admin/questionAnswerDashBoard/incommingQuestion/incommingQuestion.vue"
                ),
        },
        meta: () => ({
            pageTitle: "الاسئلة الواردة",
            roles: [Admin, SuperAdmin, Guide],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "الاسئلة الواردة",
                },
            ],
        }),
    },
    {
        path: "/admin/questionAnswerDashBoard/incommingQuestion/:id",
        name: "incommingQuestionDetails",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/questionAnswerDashBoard/incommingQuestion/pages/incommingQuestionDetails.vue"
                ),
        },
        meta: () => ({
            pageTitle: "الاسئلة الواردة",
            roles: [Admin, SuperAdmin, Guide],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "الاسئلة الواردة",
                },
            ],
        }),
    },
    {
        path: "/admin/questionAnswerDashBoard/questionRecord",
        name: "questionRecord",
        components: {
            default: () =>
                import(
                    "@admin/questionAnswerDashBoard/questionRecord/questionRecord.vue"
                ),
        },
        meta: () => ({
            pageTitle: "سجل الاجابات",
            roles: [Admin, SuperAdmin, Guide],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "سجل الاجابات",
                },
            ],
        }),
    },
    {
        path: "/admin/questionAnswerDashBoard/questionRecord/:id",
        name: "questionRecordDetails",
        props: true,
        components: {
            default: () =>
                import(
                    "@admin/questionAnswerDashBoard/questionRecord/questionRecord.vue"
                ),
        },
        meta: () => ({
            pageTitle: "سجل الاجابات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "سجل الاجابات",
                },
            ],
        }),
    },
    {
        path: "/admin/activationCode",
        name: "activationCode",
        components: {
            default: () => import("@admin/activationCode/ActivationCode.vue"),
            actions: () =>
                import("@admin/activationCode/components/AddCode.vue"),
        },
        meta: () => ({
            pageTitle: "رموز التفعيل",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "رموز التفعيل",
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceSellPoint",
        name: "invoiceSellPoints",
        component: () =>
            import("@admin/invoice/invoiceSellPoint/SellPoint.vue"),
        meta: () => ({
            pageTitle: "كشف حساب ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceSellPoint/:id",
        name: "invoiceSellPoint",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/invoice/invoiceSellPoint/pages/SellPointDetails.vue"
                ),
            actions: () =>
                import(
                    "@admin/invoice/invoiceSellPoint/pages/AddInvoiceSellPoint.vue"
                ),
        },
        meta: (route) => ({
            pageTitle: " كشف الحساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoice/invoiceSellPoint",
                },
                {
                    text: route.query ? `فواتير ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceSellPoint/:id/:invoicid",
        name: "invoiceSellPointDetails",
        props: true,

        component: () =>
            import(
                "@admin/invoice/invoiceSellPoint/components/AddSellPointInvoice.vue"
            ),
        meta: (route) => ({
            pageTitle: "كشف حساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoice/invoiceSellPoint",
                },
                {
                    text:
                        route.params.invoicid && route.params.invoicid == 0
                            ? "فاتورة جديدة"
                            : "تفاصيل الفاتورة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceGuide",
        name: "invoiceGuide",
        component: () => import("@admin/invoice/invoiceGuide/Guide.vue"),
        meta: () => ({
            pageTitle: "كشف حساب ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب مجيبو الاسئلة",
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceGuide/:id",
        name: "invoiceGuideDetails",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () =>
                import("@admin/invoice/invoiceGuide/pages/guideDetails.vue"),
            actions: () =>
                import("@admin/invoice/invoiceGuide/pages/AddInvoiceGuid.vue"),
        },
        meta: (route) => ({
            pageTitle: " كشف الحساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب مجيبو الاسئلة",
                    to: "/admin/invoice/invoiceGuide",
                },
                {
                    text: route.query ? `فواتير ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceGuide/:id/:invoicid",
        name: "invoiceDetails",
        props: true,
        component: () =>
            import(
                "@admin/invoice/invoiceGuide/components/AddGuideInvoice.vue"
            ),
        meta: (route) => ({
            pageTitle: "كشف حساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب  مجيبو الاسئلة",
                    to: "/admin/invoice/invoiceGuide",
                },
                {
                    text:
                        route.params.invoicid && route.params.invoicid == 0
                            ? "فاتورة جديدة"
                            : "تفاصيل الفاتورة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceTeacher",
        name: "invoiceTeacher",
        component: () =>
            import("@/app/admin/services/invoice/invoiceTeacher/Teachers.vue"),
        meta: () => ({
            pageTitle: "كشف حساب ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب الاساتذة",
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceTeacher/:id",
        name: "invoiceteacherDetails",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/invoice/invoiceTeacher/pages/TeachersDetails.vue"
                ),
            actions: () =>
                import(
                    "@admin/invoice/invoiceTeacher/pages/AddInvoiceteacher.vue"
                ),
        },
        meta: (route) => ({
            pageTitle: " كشف الحساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب الاساتذة",
                    to: "/admin/invoice/invoiceTeacher",
                },
                {
                    text: route.query ? `فواتير ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoice/invoiceTeacher/:id/:invoicid",
        name: "invoiceTeacherDetails",
        props: true,
        component: () =>
            import(
                "@admin/invoice/invoiceTeacher/components/AddTeacherInvoice.vue"
            ),
        meta: (route) => ({
            pageTitle: "كشف حساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bill",
                    text: " كشف حساب الاساتذة",
                    to: "/admin/invoice/invoiceTeacher",
                },
                {
                    text:
                        route.params.invoicid && route.params.invoicid == 0
                            ? "فاتورة جديدة"
                            : "تفاصيل الفاتورة",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/activationCode/package",
        name: "activationCodePackage",
        components: {
            default: () => import("@admin/activationCode/pages/AddPackage.vue"),
        },
        meta: () => ({
            pageTitle: "رموز التفعيل",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "الحزم المتوفرة",
                },
            ],
        }),
    },
    {
        path: "/admin/activationCode/package/:id",
        name: "activationCodeDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/activationCode/pages/AddPackage.vue"),
        },
        meta: () => ({
            pageTitle: "رموز التفعيل",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "تفاصيل الحزمة",
                },
            ],
        }),
    },
    {
        path: "/admin/account",
        name: "account",
        components: {
            default: () => import("@admin/account/Account.vue"),
            // actions: () => import("@admin/classes/components/AddClass.vue")
        },
        meta: () => ({
            pageTitle: " كشف حساب",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "user-circle",
                    text: " كشف حساب الاساتذة",
                },
            ],
        }),
    },
    {
        path: "/admin/students",
        name: "students",
        components: {
            default: () => import("@admin/students/Students.vue"),
            actions: () => import("@admin/students/components/AddStudent.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدم التطبيق",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                },
            ],
        }),
    },
    {
        path: "/admin/students/:id",
        name: "studentsDetails",
        props: true,
        component: () => import("@admin/students/pages/StudentDetails.vue"),
        meta: (route) => ({
            pageTitle: "مستخدم التطبيق",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                    to: "/admin/students",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/dashboardUsers",
        name: "dashboardUsers",
        components: {
            default: () => import("@admin/dashboardUsers/DashboardUsers.vue"),
            actions: () =>
                import("@admin/dashboardUsers/components/AddDashboardUser.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدمو اللوحة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "user-circle",
                    text: "مستخدمو اللوحة",
                },
            ],
        }),
    },
    {
        path: "/admin/dashboardUsers/:id",
        name: "dashUserDetail",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/dashboardUsers/pages/DashboardUserDetails.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدمو اللوحة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    to: "/admin/dashboardUsers",
                    icon: "user-circle",
                    text: "مستخدمو اللوحة",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints",
        name: "sellPoints",
        components: {
            default: () => import("@admin/sellPoints/SellPoints.vue"),
            actions: () =>
                import("@admin/sellPoints/components/AddSellPoints.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints/:id",
        name: "sellPointsDetail",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/sellPoints/pages/SellPointDetail.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقطة البيع",
                    to: "/admin/sellpoints",
                },
                {
                    text: "تفاصيل",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/questionsAnswerer",
        name: "questionsAnswerer",
        components: {
            default: () =>
                import("@admin/questionsAnswerer/QuestionsAnswerer.vue"),
            actions: () =>
                import(
                    "@admin/questionsAnswerer/components/AddQuestionAnswer.vue"
                ),
        },
        meta: () => ({
            pageTitle: "مجيبو الاسئلة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope-question",
                    text: "مجيبو الاسئلة",
                },
            ],
        }),
    },
    {
        path: "/admin/questionsAnswerer/:id",
        name: "questionsAnswererDetails",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/questionsAnswerer/pages/QuestionsAnswerDetail.vue"
                ),
        },
        meta: () => ({
            pageTitle: "مجيبو الاسئلة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope-question",
                    text: "مجيبو الاسئلة",
                    to: "/admin/questionsAnswerer",
                },
                {
                    text: " التفاصيل",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/teachers",
        name: "teachers",
        components: {
            default: () => import("@admin/teachers/Teacher.vue"),
            actions: () => import("@admin/teachers/components/AddTeacher.vue"),
        },
        meta: () => ({
            pageTitle: "الأساتذة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "user-circle",
                    text: "الأساتذة",
                },
            ],
        }),
    },
    {
        path: "/admin/teachers/:id",
        name: "teachersDetail",
        props: true,
        component: () => import("@admin/teachers/pages/TeacherDetails.vue"),
        meta: (route) => ({
            pageTitle: "الاساتذة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "user-circle",
                    text: "الاساتذة",
                    to: "/admin/teachers",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/settings",
        name: "settings",
        components: {
            default: () => import("@admin/settings/Settings.vue"),
        },
        meta: () => ({
            pageTitle: "الإعدادات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bookmark",
                    text: "الإعدادات",
                },
            ],
        }),
    },
    {
        path: "/admin/notifications",
        name: "notifications",
        components: {
            default: () => import("@admin/notifications/Notifications.vue"),
            actions: () =>
                import("@admin/notifications/components/AddNotification.vue"),
        },
        meta: () => ({
            pageTitle: "الإشعارات",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bell",
                    text: "الإشعارات",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/notifications/:id",
        name: "notifiationDetails",
        props: true,
        component: () =>
            import("@admin/notifications/pages/NotificationDetails.vue"),
        meta: () => ({
            pageTitle: "الإشعارات",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "bell",
                    text: "الإشعارات",
                    to: "/admin/notifications",
                    active: false,
                },
                {
                    text: "  تفاصيل الإشعار",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs",
        name: "contactUs",
        component: () => import("@admin/contactUs/ContactUs.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs/:id",
        name: "contactUsDetails",
        props: true,
        component: () => import("@admin/contactUs/pages/ContactUsDetails.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    to: "/admin/contactUs",
                    active: false,
                },
                {
                    text: "  تفاصيل البريد",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/adverts",
        name: "adverts",
        components: {
            default: () => import("@admin/adverts/Adverts.vue"),
            actions: () => import("@admin/adverts/components/AddAdvert.vue"),
        },
        meta: () => ({
            pageTitle: " الإعلانات",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "megaphone",
                    text: " الإعلانات",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/adverts/:id",
        name: "advertsDetails",
        props: true,
        component: () => import("@admin/adverts/pages/AdvertsDetails.vue"),
        meta: () => ({
            pageTitle: "الإعلانات",
            roles: [Admin, SuperAdmin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "megaphone",
                    text: "الإعلانات ",
                    to: "/admin/adverts",
                    active: false,
                },
                {
                    text: "  تفاصيل الإعلان",
                    active: true,
                },
            ],
        }),
    },
];
