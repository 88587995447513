import api from "@api";

export default {
    state: {
        homeDto: {},
        chartDto: {},
        year: new Date().getFullYear(),
    },
    mutations: {
        GET_HOME_DASH(state, payload) {
            state.homeDto = payload;
            console.log("my home dto ", state.homeDto);

            // state.homeDto.currentMonthCount=112
        },
        GET_CHARTS_DASH(state, payload) {
            state.chartDto = payload;
        },
        SET_YEAR(state, payload) {
            state.year = payload;
        },
    },
    actions: {
        getHomeDash({ commit }, year) {
            api.get(
                "Home/Dash/GetHome" + `${year ? `?year=${year}` : ``} `,
                ({ data }) => {
                    commit("GET_HOME_DASH", data);
                    commit("SET_YEAR", year);
                }
            );
        },
        getCharts({ commit }, year) {
            api.get(
                "Home/Dash/GetChart" + `${year ? `?year=${year}` : ``} `,
                ({ data }) => {
                    commit("GET_CHARTS_DASH", data);
                }
            );
        },
    },
};
