import api from "@api";
import router from "@/router";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        classes: [],
        students: [],
        studentDto: {
            id: "",
            name: "",
            password: "",
            email: "",
            phoneNumber: "",
            birthdate: null,
            gender: "",
            isBlocked: "",
            class: "",
            subjects: [],
            subCount: "",
            isSub: false,
            subscriptions: [
                {
                    code: "",
                    endDate: "",
                    startDate: "",
                    id: "",
                    name: "",
                    price: 0
                }
            ]
        },
        statusStudent: [
            { value: true, text: "مشترك", color: "success" },
            { value: false, text: "غير مشترك", color: "danger" }
        ],
        StudentsCols: [
            {
                label: "اسم المستخدم",
                field: "name"
            },
            {
                label: "تاريخ التسجيل",
                field: "dateCreated"
            },
            {
                label: "الصف ",
                field: "class"
            },
            {
                label: "عدد المواد ",
                field: "subjectsCount"
            },
            {
                label: "عدد الإشتراكات ",
                field: "subCount"
            },
            {
                label: " رقم الموبايل ",
                field: "phoneNumber"
            },
            {
                label: "نهاية الاشتراك ",
                field: "endDate"
            },
            {
                label: "حالة الاشتراك",
                field: "isSub"
            },
            {
                label: "التفاصيل",
                field: "details"
            }
        ],
        headerDetails: [
            {
                label: "اسم الوحدة",
                field: "name"
            },
            {
                label: "الكود",
                field: "code"
            },
            {
                label: "السعر",
                field: "price"
            },
            {
                label: "تاريخ الاشتراك",
                field: "startDate"
            },
            {
                label: "انتهاء الاشتراك",
                field: "endDate"
            },
            {
                label: "التفاصيل",
                field: "details"
            }
        ],
        filterDto: {},
        searchDto: {
            keys: [],
            query: ""
        },
        tempFiles: []
    },
    getters: {
        studentList({ students }, getter, { filter }) {
            return students.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        }
    },
    mutations: {
        Get_Students_List(state, payload) {
            state.students = payload;
        },
        Get_Classes_Id(state, payload) {
            state.classes = payload;
        },
        Add_Students(state, payload) {
            state.students.unshift(payload);
        },
        Get_Details_Student(state, payload) {
            state.studentDto = { ...payload };
        },
        Update_Student(state, payload) {
            state.studentDto = { ...payload };
        },
        Delete_Student(state, id) {
            state.students.splice(
                state.students.findIndex(item => item.id == id),
                1
            );
        },
        Delete_Students(state, ids) {
            state.students = state.students.filter(el => !ids.includes(el.id));
        },
        Set_Student_Dto(state, payload) {
            if (payload) Object.assign(state.studentDto, payload);
            else {
                state.studentDto = {
                    id: "",
                    name: "",
                    password: "",
                    phoneNumber: "",
                    birthdate: "",
                    gender: "",
                    isBlocked: "",
                    class: "",
                    subjects: [],
                    subCount: "",
                    isSub: false
                };
            }
        },
        ISBLOCK_Student(state, payload) {
            state.studentDto.isBlocked = payload;
        }
    },
    actions: {
        getStudentsList({ commit }) {
            api.get("Student/Dash/GetAll", ({ data }) => {
                commit("Get_Students_List", data);
            });
        },

        addStudents({ commit }, payload) {
            api.post(
                "Student/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Students", data);
                    commit("Reset_Student_Dto");
                },
                {
                    success: "تم إضافة الطالب بنجاح",
                    error: "فشل إضافة الطالب"
                }
            );
        },
        getStudentDetails({ commit }, id) {
            api.get(`Student/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Student", data);
            });
        },
        updateStudent({ commit }, payload) {
            api.post(
                "Student/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Student", data);
                    router.push("/admin/students");
                },
                {
                    success: "تم التعديل  بنجاح",
                    error: "فشل التعديل "
                }
            );
        },
        deleteStudent({ commit }, id) {
            api.delete(
                `Student/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Student", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الطالب",
                    success: "تم حذف الطالب بنجاح",
                    error: "فشل حذف الطالب ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteStudents({ commit }, ids) {
            api.delete(
                "Student/Dash/DeleteRange",
                () => {
                    commit("Delete_Students", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الطلاب المحددة",
                    success: "تم حذف الطلاب بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        blockStudent({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Student", true);
                },
                {
                    success: "تم حظر الطالب بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة "
                }
            );
        },
        unblockStudent({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Student", false);
                },
                {
                    success: "تم الغاء حظر الطالب بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة "
                }
            );
        }
    }
};
