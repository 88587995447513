import { getSearchResultOnRow } from "@/EK-core/util";
import router from "@/router";
import api from "@api";
export default {
    state: {
        questionsList: [],
        incommingQuestions: [],
        answerQuestions: [],
        dtoQuestion: {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            answerPrice: 0,
            isBlocked: false
        },
        questionStatus: [
            { text: "غير فعال", value: true, color: "danger" },
            { text: "فعال", value: false, color: "success" }
        ],
        dtoDetail: {
            id: "",
            name: "",
            email: "",
            phoneNumber: "",
            dateCreated: "",
            isBlocked: false,
            answers: []
        },
        questionAnswerDashUserDto: {
            id: "",
            studentName: "",
            subjectName: "",
            content: "",
            dateCreated: "",
            answers: [
  
            ]
        },
        columnsAnswer: [
            {
                label: " السؤال ",
                field: "question"
            },
            {
                label: " اسم المادة",
                field: "subject"
            },
            {
                label: " السعر ",
                field: "price"
            },
            {
                label: " تاريخ السؤال",
                field: "questionDate"
            },
            {
                label: " تاريخ الاجابة",
                field: "answerDate"
            },
            {
                label: " تفاصيل",
                field: "details"
            }
        ],

        columns: [
            {
                label: " الاسم المجيب ",
                field: "name"
            },
            {
                label: "  تاريخ الاشتراك  ",
                field: "dateCreated"
            },
            {
                label: "  عدد الاجابات ",
                field: "answersCount"
            },
            {
                label: " حالة الاشتراك ",
                field: "isBlocked"
            },
            {
                label: " تفاصيل",
                field: "details"
            }
        ]
    },
    mutations: {
        GET_ALL_QUESTIONS_ANSWER(state, payload) {
            state.questionsList = payload;
        },
        GET_ALL_QUESTIONS(state, payload) {
            state.incommingQuestions = payload;
            console.log(payload);
        },
        GET_ALL_ANSWER(state, payload) {
            state.answerQuestions = payload;
            console.log(payload);
        },
        GET_BYID_QUESTIONS(state, payload) {
            state.dtoDetail = { ...payload };
        },
        RESET_DTO(state) {
            state.dtoQuestion = {
                name: "",
                phoneNumber: "",
                email: "",
                password: "",
                answerPrice: 0,
                isBlocked: false
            };
        },
        ADD_QUESTIONS(state, payload) {
            state.questionsList.unshift(payload);
        },
        ADD_ANSWER(state,payload){
            state.questionAnswerDashUserDto.answers.push(payload)
        },
        DELETE_QUESTION(state, payload) {
            state.questionsList = state.questionsList.filter(
                ele => ele.id != payload
            );
            router.push("/admin/questionsAnswerer");
        },
        DELETE_QUESTIONS(state, payload) {
            state.questionsList = state.questionsList.filter(
                ele => !payload.includes(ele.id)
            );
        },
        UPDATE_QUESTIONS(state, paylaod) {
            state.dtoQuestion = { ...paylaod, newPassword: "" };
        },
        ISBLOCK_QUESTION_ANSWER(state, payload) {
            state.dtoDetail.isBlocked = payload;
        },
        GET_BYID_QUESTIONS_DASHUSER(state, payload) {
            console.log('adddessdsdsdasd ', payload);
            state.questionAnswerDashUserDto = { ...payload, files: [] };
        },
        UPDATE_ANSWER_DASHUSER(state, payload) {
            state.questionAnswerDashUserDto.answers = { ...payload };
        },
        REASET_QUESTION_DASHUSER_DTO(state) {
            state.questionAnswerDashUserDto = {
                id: "",
                studentName: "",
                subjectName: "",
                content: "",
                dateCreated: "",
                answers: [
                    {
                        id: "",
                        content: "",
                        urls: []
                    }
                ]
            };
        }
        // UNBLOCK_QUESTION_ANSWER(state, payload) {}
    },

    actions: {
        getAllQuestionsAnswerer({ commit }) {
            api.get("Guide/Dash/GetAll", ({ data }) => {
                commit("GET_ALL_QUESTIONS_ANSWER", data);
            });
        },
        getAllQuestions({ commit }) {
            api.get("Guide/Dash/GetAllQuestion", ({ data }) => {
                commit("GET_ALL_QUESTIONS", data);
                console.log(data);
            });
        },
        getAllAnswer({ commit }) {
            api.get("Guide/Dash/GetAllAnswer", ({ data }) => {
                commit("GET_ALL_ANSWER", data);
            });
        },
        getByIdQuestionsAnswerer({ commit }, id) {
            api.get(`Guide/Dash/GetById?id=${id}`, ({ data }) => {
                commit("GET_BYID_QUESTIONS", data);
            });
        },
        getByIdQuestionDashUser({ commit ,}, id) {
            api.get(
                `Guide/Dash/GetByIdQuestion?questionId=${id}`,
                ({ data }) => {
                    
                    commit("GET_BYID_QUESTIONS_DASHUSER", data);
                    console.log(data);
                }
            );
        },
        addQuestionsAnswerer({ commit }, payload) {
            api.post(
                "Guide/Dash/Add",
                payload,
                ({ data }) => {
                    commit("ADD_QUESTIONS", data);
                },
                {
                    success: "تم اضافة مجيب الاسئلة بنجاح  ",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        modifyQuestionAnswerDashUser({ commit }, payload) {
            api.form.post(
                "Guide/Dash/ModifyAnswer",
                payload,
                ({ data }) => {
                    commit("UPDATE_ANSWER_DASHUSER", data);
                },
                {
                    success: "تم تعديل الاسئلة بنجاح  ",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        addNewAnswer({commit ,  dispatch}, dto) {
            console.log(dto);
            api.form.post(
                "Guide/Dash/AddAnswer",
                dto,
                ({ data }) => {
                    dispatch('getByIdQuestionDashUser' ,  dto.questionId)

                    commit('ADD_ANSWER' ,{...dto , id:data.id})
                },
                {
                    success: "تمت اضافة الإاجابة بنجاح !"
                }
            );
        },
        updateQuestionsAnswerer({ commit }, payload) {
            api.post(
                "Guide/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("UPDATE_QUESTIONS", data);
                    router.push("/admin/questionsAnswerer");
                },
                {
                    success: "تم تعديل هذا السؤال بنجاح",
                    error: "فشل تعديل ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteQuestionAnswerer({ commit }, id) {
            api.delete(
                `Guide/Dash/Delete?id=${id}`,
                ({ data }) => {
                    commit("DELETE_QUESTION", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف هذا السؤال    ",
                    success: "تم حذف هذا السؤال بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteQuestionsAnswerer({ commit }, ids) {
            api.delete(
                "Guide/Dash/DeleteRange",
                () => {
                    commit("DELETE_QUESTIONS", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف هذه الاسئلة   ",
                    success: "تم حذف هذه الاسئلة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        blockQuestionAnswerer({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_QUESTION_ANSWER", true);
                },
                {
                    success: "تم حظر مجيب السؤال بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة "
                }
            );
        },
        unblockQuestionAnswerer({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_QUESTION_ANSWER", false);
                },
                {
                    success: "تم الغاء حظر مجيب السؤال بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة "
                }
            );
        }
    },
    getters: {
        questionAnswerList({ questionsList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return questionsList.filter(el => {
                return (
                    getSearchResultOnRow(filter, el) || el.answersCount == query
                );
            });
        }
    }
};
