import api from "@api";
import router from "@/router";

export default {
    state: {
        adverts: [],
        advertDto: {},
        advertEnum: {
            1: "مقطع فيديو ",
            2: "صورة"
        },
        advertOptions: [
            {
                id: 1,
                name: "مقطع فيديو "
            },
            {
                id: 2,
                name: "صورة"
            }
        ],
        statusAdvertList: [
            {
                value: 1 ,
                text: "فعال" ,
                color: "success"
            },
            {
                value: 2 ,
                text: "منتهي" ,
                color: "danger"
            },
            {
                value: 3,
                text: "غير فعال" ,
                color: "secondary"
            }
            
        ],
    },
    getters:{
        adsList({adverts}, getters, { filter }) {
            let query = filter.searchDto.query;
            return adverts.filter(ele => {
                return (
                    ele.titl.indexOf(query) != -1 ||
                    ele.content == query ||
                    ele.startDate == query||
                    ele.endDate == query
                )
            });
        },
    },
    mutations: {
        Get_Ads_List(state, payload) {
            state.adverts = payload 
        },
        Update_Ads_Dto_Date(state, payload) {
            state.advertDto = {
                ...state.advertDto,
                startDate: payload.split("to")[0],
                endDate: payload.split("to")[1],
            };
        },
        Add_Ads(state, payload) {
            state.adverts.unshift(payload);
        },
        Reset_Ads_Dto(state) {
            Object.assign(state.adverts, {
                id: "",
                titl: "",
                content: "",
                startDate: "",
                endDate: "",
                file: "", 
                thumbFile:"",
                url:"",
                thumbUrl:""
            });
        }, 
        /* إرجاع حالة الإعلان مع تاريخ البداية والنهاية */
        Get_Details_Ads(state, payload) {
            state.advertDto = {
                ...payload,
                adsDatesList: [
                    new Date(payload.startDate),
                    new Date(payload.endDate),
                ],
                statusAds: [
                    new Date(payload.endDate) >= Date.now() &&
                    new Date(payload.startDate) <= Date.now()
                        ? 1
                        : new Date (payload.startDate) > Date.now() 
                        ? 3 
                        : 2
                ],
            };
        },
        Update_Ads(state, payload) {
            state.advertDto = { ...payload } 
        },
        Delete_Ad(state, id) {
            state.adverts.splice(
                state.adverts.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Ads(state, ids) {
            state.adverts = state.adverts.filter(
                (el) => !ids.includes(el.id)
            ); 
        },
    },
    actions: {
        getAdsList({ commit }) {
            api.get("Ad/Dash/GetAll", ({ data }) => {
                commit("Get_Ads_List", data)
            });
        },
        getAdsDetails({ commit }, id) {
            api.get(`Ad/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Ads", data);
                commit("Reset_Ads_Dto");
            });
        },
        addAds({ commit }, payload) {
            return new Promise((resolve)=> {
                api.form.post(
                     "Ad/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Ads", res.data);
                        commit("Reset_Ads_Dto");
                        resolve(res)
                    },
                    {
                        success: "تم إضافة الإعلان بنجاح",
                        error: "فشل إضافة الاعلان",
                    }
                );
            })
        },
        updateAds({ commit }, payload) {
            return new Promise((resolve)=> {
                api.form.post(
                     "Ad/Dash/Modify",
                    payload,
                    (res) => {
                        commit("Update_Ads", res.data);
                        commit("Reset_Ads_Dto");
                        router.push("/admin/adverts");
                        resolve(res)
                    },
                    {
                        success: "تم تعديل الإعلان بنجاح",
                        error: "فشل التعديل ",  
                    }
                );
            })
        },
        deleteAd({ commit }, id) {
            api.delete(
                `Ad/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Ad", id);
                    router.push("/admin/adverts");
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الاعلان",
                    success: "تم حذف الاعلان بنجاح",
                    error: "فشل حذف الاعلان ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteAds({ commit }, ids) {
            api.delete(
                "Ad/Dash/DeleteRenge",
                () => {
                    commit("Delete_Ads", ids);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الاعلانات المحددة؟",
                    success: "تم حذف الاعلانات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};