import api from "@/EK-core/api";
// import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";

export default {
    state: {
        progress: null,
        lessons: [],
        uploadLiveLesson: {
            lessonId: "",
            file: "",
            thumbFile: "",
        },
        lessonDto: {},
        newLessonType: 1,
        filterValue: {
            filterValueSemester: '',
            filterValueType: 0,
            filterValueSubject: "",
        },
        questionsPagination: {
            pageNumber: 1,
            pageSize: 10,
        },
        lessonForm: {
            unitId: "",
            chapterId: "",
            name: "",
            description: "",
            order: "",
            semester: '',
            file: null,
            thumbFile: null,
            scheduleDate: "",
            url: "",
            isFree: false,
            mediaType: 2,
        },
        questionLessonDto: {
            id: "",
            isAnswerd: true,
            studentName: "",
            subjectName: "",
            answersCount: 0,
            content: "",
            dateCreated: "",
            answers: [
                {
                    id: "",
                    userId: "",
                    name: "",
                    content: "",
                    dateCreated: "",
                    urls: [],
                },
            ],
        },

        lessonsQuiz: [],
        lessonDetailsQuiz: [],
        answerEnum: {
            1: "مقطع فيديو",
            2: "صورة",
            3: "تسجيل صوت",
            4: "ملف",
            5: "نص",
        },
        semesterList: [],

        // semesterList: [
        //     { id: 1, name: "الفصل الاول" },
        //     { id: 2, name: "الفصل الثاني" },
        // ],
        lessonTypeList: [
            { id: 0, name: " الكل" },
            { id: 1, name: "مقطع فيديو" },
            { id: 2, name: "ملف" },
            { id: 3, name: " اختبار" },
            { id: 4, name: "بث مباشر" },
        ],
        lessonEnum: {
            1: "مقطع فيديو ",
            2: "ملف",
            3: "اختبار",
            4: "بث مباشر",
        },
        lessonOptions: [
            {
                id: 1,
                name: "مقطع فيديو ",
            },
            {
                id: 2,
                name: "ملف",
            },
            {
                id: 3,
                name: "اختبار",
            },
        ],
        lessonsQuizHeader: [
            {
                field: "order",
                label: "رقم السؤال  ",
            },
            {
                field: "content",
                label: " السؤال  ",
            },
            {
                field: "dateCreated",
                label: " تاريخ الإضافة  ",
            },
            {
                field: "answersCount",
                label: "عدد المجيبين  ",
            },
            {
                field: "details",
                label: "التفاصيل",
            },
        ],
        lessonsQuizUnit: [
            {
                field: "order",
                label: "رقم السؤال  ",
            },

            {
                field: "dateCreated",
                label: " تاريخ الإضافة  ",
            },
            {
                field: "answersCount",
                label: "عدد المجيبين  ",
            },
            {
                field: "details",
                label: "التفاصيل",
            },
        ],
        lessonsQuizHeaderDetails: [
            {
                field: "order",
                label: "رقم السؤال  ",
            },
            {
                field: "dateCreated",
                label: " تاريخ الإضافة  ",
            },
            {
                field: "answersCount",
                label: "عدد المجيبين  ",
            },
            {
                field: "details",
                label: "التفاصيل",
            },
        ],
        lessonsTableCols: [
            {
                field: "name",
                label: "اسم الدرس ",
            },
            {
                field: "order",
                label: "الترتيب ",
            },
            {
                field: "dateCreated",
                label: "تاريخ الإضافة ",
            },
            {
                field: "lessonType",
                label: "نوع المستند",
            },
            {
                field: "unitName",
                label: "اسم الوحدة",
            },
            {
                field: "subjectName",
                label: "اسم المادة",
            },
            {
                field: "chapterName",
                label: "اسم البحث",
            },
            {
                field: "semester",
                label: "الفصل",
            },
            {
                field: "className",
                label: "الصف",
            },
            {
                field: "teacherName",
                label: "المدرس",
            },
            {
                field: "studentsCount",
                label: "عدد الطلاب",
            },
            {
                field: "details",
                label: "التفاصيل",
            },
        ],
        selected: [
            { id: 1, name: "نص" },
            { id: 2, name: "صورة" },
            { id: 3, name: "نص - صورة" },
        ],
        semesterOptions: [
            { name: " الاول", id: 1 },
            { name: " الثاني", id: 2 },
            { name: "جلسات امتحانية", id: 3 },
        ],
        semesterEnum: {
            1: "الفصل الاول ",
            2: "الفصل الثاني",
            3: "جلسات امتحانية",
        },
        lessonFilterDto: {
            type: "",
            subject: "",
            semester: "",
        },
        lessonLiveDto: {
            description: "",
            name: "",
            order: 0,
            scheduleDate: "",
            unitId: "",
            url: "",
        },
    },
    mutations: {
        SET_UPLOAD_PROGRESS(state, payload) {
            state.progress = payload;
        },
        Upload_Vide_Live_Lesson(state, payload) {
            state.uploadLiveLesson = { ...payload };
        },
        RESET_QUESTION_LESSONDTO(state) {
            // Object.assign(state.resetQuestionLessonDto, {});
            state.questionLessonDto = {
                id: "",
                isAnswerd: true,
                studentName: "",
                subjectName: "",
                answersCount: 0,
                content: "",
                dateCreated: "",
                answers: [
                    {
                        id: "",
                        userId: "",
                        name: "",
                        content: "",
                        dateCreated: "",
                        urls: [],
                    },
                ],
            };
        },
        PUSH_STUDENT_QUESTION(state, payload) {
            state.lessonDto.questions.push(payload);
        },
        MODIFY_STUDENT_QUESTION(state, payload) {
            state.questionLessonDto = { ...payload };
        },
        SET_LESSONS(state, payload) {
            state.lessons = [...payload];
        },
        SET_LESSON_DTO(state, payload) {
            state.lessonDto = { ...payload };
        },
        SET_NEW_LESSON_TYPE(state, { newLessonType, dto }) {
            state.newLessonType = newLessonType;
            if (dto) state.lessonDto = { ...dto };
        },
        SET_LESSON_ORDER(state, order) {
            state.lessonForm.order = order;
        },
        RESET_LESSON_FORM(state, { unitId, chapterId }) {
            state.lessonForm = {
                unitId: unitId,
                chapterId: chapterId,
                name: "",
                description: "",
                order: "",
                semester: '',
                file: null,
                thumbFile: null,
            };
        },
        ADD_LESSON(state, payload) {
            state.lessons.unshift(payload);
        },
        SET_VIDEO_UPLOAD_PROGRESS(state, payload) {
            state.videoUploadProgress = payload;
        },
        UPDATE_DTO_URL(state, payload) {
            state.lessonDto = {
                ...state.lessonDto,
                url: payload.data,
                isDecrypted: true,
            };
        },
        Get_Student_Questions(state, payload) {
            // state.questionLessonDto = { ...payload };
            // console.log(payload);
            state.questionLessonDto = {
                ...payload,
                answers: payload.answers.map((el) => ({
                    ...el,
                    medias: [
                        {
                            file: "",
                            mediaType: 1,
                            thumbFile: "",
                        },
                    ],
                    deletedMedias: [],
                })),
            };
            console.log(state.questionLessonDto);
        },
        SET_BY_ID_QUIZ(state, payload) {
            state.lessonsQuiz = [...payload];
        },
        SET_QUIZ_LESSON(state, payload) {
            state.lessonDetailsQuiz = [...payload];
        },
        DELETE_LESSON(state, id) {
            state.lessons.splice(
                state.lessons.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Lessons(state, ids) {
            state.lessons = state.lessons.filter((el) => !ids.includes(el.id));
        },
        Delete_Questions_Quiz(state, ids) {
            state.lessonDto.questions = state.lessonDto.questions.filter(
                (el) => !ids.includes(el.id)
            );
        },
        Get_Settings_Semester(state, payload) {
            state.semesterList = payload;
        },
    },
    getters: {
        getLessonDto: (state) => state.lessonDto,
        resultLessonList(
            {
                lessons,
                filterValue,
                // filterValueSemester,
                // filterValueType,
                // filterValueSubject
            },
            getters,
            { filter }
        ) {
            let query = filter.searchDto.query;
             return lessons.filter((el) => {
                return (
                    (el.className.indexOf(query) != -1 ||
                       
                        el.name.indexOf(query) != -1 
                        ||
                    
                        el.subjectName.indexOf(query) != -1 ||
                        el.teacherName.indexOf(query) != -1 ||
                        el.unitName.indexOf(query) != -1)
                         &&
                    (el.semester == filterValue.filterValueSemester ||
                        !filterValue.filterValueSemester) 
                        &&
                    (el.lessonType == filterValue.filterValueType ||
                        !filterValue.filterValueType) 
                        &&
                    (el.subjectId == filterValue.filterValueSubject ||
                        !filterValue.filterValueSubject)
                );
            });
        },
    },
    actions: {
        
        getSettingSemester({ commit }) {
            api.get("Semester/Dash/GetAll", ({ data }) => {
                commit("Get_Settings_Semester", data);
            });
        },
        addTeacherAnswer(_, payload) {
            api.post("Syllabus/Dash/AddCommentLiveLesson", payload);
        },
        uploadVideoToLive({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "Syllabus/Dash/UploadVideoToLiveLesson",
                    payload,
                    (res) => {
                        commit("Upload_Vide_Live_Lesson", res.data);
                        resolve(res);
                        router.go(-1);
                    },
                    {
                        success: "تم تحويل البث المباشر إلى فيديو  بنجاح",
                        error: "فشل التحويل ",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        getStudentQuestion({ commit }, id) {
            return new Promise((resolve) => {
                api.get(
                    `Syllabus/Dash/GetStudentQuestion?id=${id}`,
                    ({ data }) => {
                        commit("Get_Student_Questions", data);
                        resolve(data);
                    }
                );
            });
        },
        modifyStudentQuestion({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                api.form.post(
                    `Syllabus/Dash/ModifyAnswerForStudentQuestion`,
                    payload,
                    ({ data }) => {
                        commit("MODIFY_STUDENT_QUESTION", data);
                        resolve(data);
                    },
                    {
                        success: "تم تعديل الاجابات !",
                        error: "فشل التعديل الاجابات ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addAnswerQuestionStudent(_, payload) {
            return new Promise((resolve) => {
                api.form.post(
                    "Syllabus/Dash/AddAnswerForStudentQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data);
                        router.push(router.go(-1));
                    },
                    {
                        success: "تم إضافة الإجابة بنجاح !",
                        error: "فشل الإضافة..يرجى اعادة المحاولة !",
                    }
                );
            });
        },
        deleteLesson({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteLesson?id=${id}`,
                () => {
                    commit("DELETE_LESSON", id);
                    router.go(-1);
                },
                {
                    confirm: "هل تريد بالتأكيد حذف الدرس !",
                    success: "تم حذف الدرس بنجاح !",
                    error: "فشل الحذف..يرجى اعادة المحاولة !",
                }
            );
        },
        decryptFile({ commit }, url) {
            return new Promise((resolve) => {
                api.get(`Setting/GetFileLink?url=${url}`, (res) => {
                    resolve(res);
                    commit("UPDATE_DTO_URL", res);
                });
            });
        },
        getLessons({ commit }) {
            api.get(
                "Syllabus/Dash/GetAllLesson",
                ({ data }) => {
                    commit("SET_LESSONS", data);
                },
                (er) => {
                    console.log(er);
                }
            );
        },
        getLessonLive({ commit }, id) {
            return new Promise((resolve) => {
                api.get(
                    `Syllabus/Dash/GetByIdLiveLesson?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_LESSON_DTO", { ...data, lessonType: 4 });
                    }
                );
            });
        },
        getLessonVideo({ commit }, id) {
            return new Promise((resolve) => {
                api.get(
                    `Syllabus/Dash/GetByIdVideoLesson?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_LESSON_DTO", { ...data, lessonType: 1 });
                    }
                );
            });
        },
        getLessonFile({ commit }, id) {
            return new Promise((resolve) => {
                api.get(
                    `Syllabus/Dash/GetByIdFileLesson?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_LESSON_DTO", { ...data, lessonType: 2 });
                    }
                );
            });
        },
        getLessonQuiz({ commit }, id) {
            console.log(id);
            return new Promise((resolve) => {
                api.get(
                    `Syllabus/Dash/GetByIdQuizLesson?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        console.log(data);
                        commit("SET_LESSON_DTO", { ...data, lessonType: 3 });
                    }
                );
            });
        },
        getByIdQuiz({ commit }, id) {
            api.get(`Syllabus/Dash/GetByIdQuiz?id=${id}`, ({ data }) => {
                commit("SET_BY_ID_QUIZ", data);
            });
        },
        finishLiveLesson(_, lessonId) {
            return new Promise((resolve) => {
                api.post(
                    `Syllabus/Dash/FinishLiveLesson?lessonId=${lessonId}`,
                    ({ data }) => {
                        resolve(data);
                    },
                    {
                        success: "تم إنهاء البث  بنجاح",
                        error: "فشل الإنهاء ..يرجى اعادة المحاولة ",
                    }
                );
            });
        },
        addComment({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/AddCommentLiveLesson",
                    payload,
                    (res) => {
                        resolve(res.data);
                        commit("Add_Comment", res.data);
                    },
                    {
                        success: "تمت اضافة الإجابة بنجاح",
                        error: "فشلت اضافة الإجابة ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addVideoLesson({ commit }, payload) {
            console.log("Add Action fired", payload);
            const config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = parseInt(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                    console.log("progress  . . . ", percentCompleted);
                    console.log("total  . . . ", progressEvent.total);
                    console.log("loaded  . . . ", progressEvent.loaded);
                    commit("SET_UPLOAD_PROGRESS", percentCompleted);
                },
                noLoading: true,
            };
            return new Promise((resolve, reject) => {
                api.form.post(
                    "Syllabus/Dash/AddVideoLesson",
                    payload,
                    (res) => {
                        resolve(res.data);
                        commit("ADD_LESSON", res.data);
                        commit("SET_UPLOAD_PROGRESS", null);
                    },
                    {
                        success: "تمت اضافة الدرس بنجاح",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        commit("SET_UPLOAD_PROGRESS", null);

                        reject(er);
                    },
                    config
                );
            });
        },

        addFileLesson({ commit }, payload) {
            let fm = new FormData();
            const config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = parseInt(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                    console.log("progress  . . . ", percentCompleted);
                    console.log("total  . . . ", progressEvent.total);
                    console.log("loaded  . . . ", progressEvent.loaded);
                    commit("SET_UPLOAD_PROGRESS", percentCompleted);
                },
                noLoading: true,
            };
            for (let key in payload) fm.append(key, payload[key]);
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/AddFileLesson",
                    fm,
                    ({ data }) => {
                        commit("ADD_LESSON", data);
                        resolve(data);
                    },
                    {
                        success: "تمت اضافة الدرس بنجاح",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    },
                    config
                );
            });
            return prom;
        },
        addLiveLesson({ commit }, payload) {
            console.log("PAYLOAD", payload);
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/AddLiveLesson",
                    payload,
                    (res) => {
                        resolve(res.data);
                        commit("ADD_LESSON", res.data);
                    },
                    {
                        success: "تمت اضافة الدرس بنجاح",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
            return prom;
        },
        addQuizLesson({ commit }, payload) {
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/AddQuizLesson",
                    payload,
                    (res) => {
                        commit("ADD_LESSON", res.data);
                        // commit("SET_LESSONS", res.data);
                        resolve(res.data);
                    },
                    {
                        success: "تمت اضافة الدرس بنجاح",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
            return prom;
        },

        updateVideoLesson({ commit }, payload) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    console.log("onLoad progress");
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );

                    console.log(percentCompleted);
                    commit("SET_VIDEO_UPLOAD_PROGRESS", percentCompleted);
                },
                noLoading: true,
            };

            return new Promise((resolve, reject) => {
                api.form.post(
                    "Syllabus/Dash/ModifyVideoLesson",
                    payload,
                    ({ data }) => {
                        commit("SET_LESSON_DTO", data);
                        commit("SET_UPLOAD_PROGRESS", null);

                        resolve(data);
                    },
                    {
                        success: "تم تعديل الدرس بنجاح",
                        error: "فشل تعديل الدرس..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                        commit("SET_UPLOAD_PROGRESS", null);
                    },
                    config
                );
            });
        },
        updateFileLesson({ commit }, payload) {
            let fm = new FormData();
            for (let key in payload) fm.append(key, payload[key]);
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/ModifyFileLesson",
                    fm,
                    ({ data }) => {
                        commit("SET_LESSON_DTO", data);
                        resolve(data);
                    },
                    {
                        success: "تم تعديل الدرس بنجاح",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
            return prom;
        },
        updateLiveLesson({ commit }, payload) {
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/ModifyLiveLesson",
                    payload,
                    (res) => {
                        commit("SET_LESSON_DTO", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم التعديل  !",
                        error: "فشلت تعديل الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
            return prom;
        },
        updateQuizLesson({ commit }, payload) {
            let prom = new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/ModifyQuizLesson",
                    payload,
                    (res) => {
                        commit("SET_LESSON_DTO", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم تعديل الدرس بنجاح !",
                        error: "فشلت اضافة الدرس ..يرجى اعادة المحاولة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
            return prom;
        },
        deleteStudentQuestion({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteStudentQuestion?id=${id}`,
                () => {
                    commit("Delete_Student_Question", id);
                    router.go(-1);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف السؤال",
                    success: "تم حذف السؤال بنجاح",
                    error: "فشل حذف السؤال ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteRangeLessons({ commit }, ids) {
            api.delete(
                "Syllabus/Dash/DeleteRangeLesson",
                () => {
                    commit("Delete_Lessons", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الدروس المحددة",
                    success: "تم حذف الدروس بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deleteRangeQuestionQuiz({ commit }, ids) {
            api.delete(
                "QuestionBank/Dash/DeleteRange",
                () => {
                    commit("Delete_Questions_Quiz", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف اسئلة الاختبارات المحددة",
                    success: "تم حذف الاختبارات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
