import router from "@/router";
import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
// import axiosIns from "@/libs/axios";
// import Swal from "sweetalert2";


export default {
    state: {
        guides: [],
        guidesDetails: [],
        guideUnpaid:[],
        guidesCols: [
            {
                label: "اسم المجيب ",
                field: "name",
            },
            {
                label: "عدد الأجابات",
                field: "countAnswer",
            },
            {
                label: "تاريخ آخر مطابقة",
                field: "lastAccordanceDate",
            },
            {
                label: "الرصيد المستحق له",
                field: "guidePaid",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        DetailsGuideCols: [
            {
                label: " رقم الفاتورة",
                field: "serialNumber",
            },
            {
                label: "تاريخ الاجابة",
                field: "date",
            },
            {
                label: "  عدد الاجابات",
                field: "answerCount",
            },
            {
                label: "قيمة الفاتورة",
                field: "value",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        
        filterDto: {},
        searchDto: {
            keys: [],
            query: "",
        },
        tempFiles: [],
    },
    getters:{
        guideList(state) {
            return state.guides.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        guideDetailsList({ guidesDetails }, getter , { filter }) {
            return guidesDetails.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        },
        guideUnpaidList(state) {
            return state.guideUnpaid.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
    },
    mutations: {
        Get_Guides_List(state, payload) {
            state.guides = payload 
        },
        Get_Details_Guide_List(state, payload) {
            state.guidesDetails = payload
        },
        Get_Unpaid_Guide_List(state, payload) {
            console.log('guidMutations',payload)
            state.guideUnpaid = payload 
        }
    },
    actions: {
        getGuidesList({ commit }) {
            api.get("Invoice/Dash/GetAllGuide", ({ data }) => {
                commit("Get_Guides_List", data)
            });
        },
        getDetailsGuideList({ commit }, id) {
            api.get(`Invoice/Dash/GetDetailsGuide?id=${id}`, ({ data }) => {
                commit('Get_Details_Guide_List', data)
            })
        },
        getUnpaidGuideList({ commit }, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetUnpaidByGuideId?id=${id}`,
                ({ data }) => {
                    console.log('guidActions',data)
                    commit("Get_Unpaid_Guide_List", data),
                    resolve(data)
                },
                );
            })
        },
        addGuide(_, payload) {
            return new Promise(resolve => {
                api.post(
                    "Invoice/Dash/AddInvoiceToGuide",
                    payload,
                    ({ data }) => {
                        resolve(data)
                        router.push(router.go(-1))
                    },
                    {
                        success: "تم إضافة الفاتورة بنجاح",
                        error: "فشل إضافة الفاتورة",
                    }
                );
            })
        },
        getGuideDetails(_, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetDetailsInvoiceGuide?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                    }
                );
            });
        },
        deleteInvoice(_, id) {
            api.delete(
                `Invoice/Dash/Delete?id=${id}`,
                () => {
                    router.go(-1)
                    
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الفاتورة",
                    success: "تم حذف الفاتورة بنجاح",
                    error: "فشل حذف الفاتورة ..يرجى اعادة المحاولة ",
                }
            );
        },
    },
};