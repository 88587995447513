import api from "@api";
import router from "@/router";
// import { getSearchResultOnRow } from "@Ekcore/util";
// import { getUserData } from "@/app/admin/auth/utils";

export default {
    state: {
        classes: [],
        subjects: [],
        subjectOptions: [],
        isDialogOpen: false,
        subjectDto: {
            id: "",
            name: "",
            colors: [],
            dateCreated: "",
            file: "",
            iconFile: "",
            educationalLevel: "",
            unitesCount: 0,
            studentsCount: 0,
            classId: "",
            className: "",
            iconUrl: "",
            url: "",
            unites: [
                {
                    id: "",
                    name: "",
                    dateCreated: "",
                    lessonsCount: "",
                    subCount: "",
                    teacherName: ""
                }
            ]
        },
        headerDetails: [
            {
                label: "اسم الوحدة",
                field: "name"
            },
            {
                label: "تاريخ الإضافة",
                field: "dateCreated"
            },
            {
                label: "عدد الدروس",
                field: "lessonsCount"
            },
            {
                label: "اسم المدرس",
                field: "teacherName"
            },
            {
                label: " عدد الإشتراكات",
                field: "subCount"
            },
            {
                label: " التفاصيل",
                field: "details"
            }
        ],
        educationLevelList: [
            { id: 1, name: "المرحلة الابتدائية" },
            { id: 2, name: "المرحلة الاعدادية" },
            { id: 3, name: "المرحلة الثانوية" }
        ],
        semesterList: [
            { id: 1, name: "الفصل الاول" },
            { id: 2, name: "الفصل الثاني" }
        ],
        filterDto: {},
        searchDto: {
            keys: [],
            query: ""
        },
        tempFiles: []
    },
    getters: {
        subjectsList({ subjects, educationLevelList }, getters, { filter }) {
             let query = filter.searchDto.query;
            return subjects.filter(ele => {
                return (
                    ele.name.indexOf(query) != -1 ||
                    educationLevelList
                        .find(item => item.id == ele.educationalLevel)
                        .name.indexOf(query) != -1 ||
                    ele.studentsCount == query ||
                    ele.unitesCount == query
                )
            });
        }
    },
    mutations: {
        Get_Subjects_List(state, payload) {
            state.subjects = payload;
        },
        Get_Classes_Id(state, payload) {
            state.classes = payload;
        },
        Add_Subjects(state, payload) {
            state.subjects.unshift(payload);
        },
        Reset_Subjects_Dto(state) {
            Object.assign(state.subjectDto, {
                id: "",
                name: "",
                colors: [],
                semester: "",
                dateCreated: "",
                file: "",
                iconFile: "",
                educationalLevel: "",
                unitesCount: "",
                studentsCount: "",
                classId: "",
                className: "",
                iconUrl: "",
                url: ""
            });
        },
        Get_Details_Subject(state, payload) {
            state.subjectDto = { ...payload };
        },
        Update_Subject(state, payload) {
            state.subjectDto = { ...payload };
        },
        Delete_Subject(state, id) {
            state.subjects.splice(
                state.subjects.findIndex(item => item.id == id),
                1
            );
        },
        Delete_Subjects(state, ids) {
            state.subjects = state.subjects.filter(el => !ids.includes(el.id));
        },
        Is_Dialog_Open(state, payload) {
            state.isDialogOpen = payload;
        },
        Set_Subjects_Dto(state, payload) {
            if (payload) Object.assign(state.subjectDto, payload);
            else {
                state.subjectDto = {
                    id: "",
                    name: "",
                    colors: [],
                    semester: "",
                    dateCreated: "",
                    file: "",
                    iconFile: "",
                    educationalLevel: "",
                    unitesCount: "",
                    studentsCount: "",
                    classId: "",
                    className: "",
                    iconUrl: "",
                    url: ""
                };
            }
        },
        SET_SUBJECTS_OPTIONS(state, payload) {
            state.subjectOptions = payload;
        }
    },
    actions: {
        getSubjectsList({ commit }) {
            api.get("Syllabus/Dash/GetAllSubject", ({ data }) => {
                commit("Get_Subjects_List", data);
            });
        },

        addSubject({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "Syllabus/Dash/AddSubject",
                    payload,
                    res => {
                        commit("Add_Subjects", res.data);
                        commit("Set_Subjects_Dto");
                        resolve(res);
                    },
                    {
                        success: "تم إضافة المادة بنجاح",
                        error: "فشل إضافة المادة"
                    },
                    er => {
                        reject(er);
                    }
                );
            });
        },

        getSubjectDetails({ commit }, id) {
            api.get(`Syllabus/Dash/GetByIdSubject?id=${id}`, ({ data }) => {
                commit("Get_Details_Subject", data);
            });
        },
        updateSubject({ commit }, payload) {
            api.form.post(
                "Syllabus/Dash/ModifySubject",
                payload,
                ({ data }) => {
                    commit("Update_Subject", data);
                    commit("Set_Subjects_Dto", data);
                    router.push("/admin/subjects");
                },
                {
                    success: "تم تعديل المادة  بنجاح",
                    error: "فشل تعديل المادة "
                }
            );
        },
        deleteSubject({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteSubject?id=${id}`,
                () => {
                    commit("Delete_Subject", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف المادة",
                    success: "تم حذف المادة بنجاح",
                    error: "فشل حذف المادة ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteSubjects({ commit }, ids) {
            api.delete(
                "Syllabus/Dash/DeleteRangeSubject",
                () => {
                    commit("Delete_Subjects", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف المواد المحددة",
                    success: "تم حذف المواد بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        getSubjectOptions({ commit }) {
            api.get("Syllabus/Dash/GetSubjectsId", ({ data }) => {
                commit("SET_SUBJECTS_OPTIONS", data);
            });
        }
    }
};
